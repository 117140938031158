<template>
  <el-dropdown size="small" class="d2-mr">
    <span class="btn-text">{{
      info.name ? `你好 ${info.name}` : "未登录"
    }}</span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="logOff">
        <d2-icon name="power-off" class="d2-mr-5" />
        注销
      </el-dropdown-item>
      <el-dropdown-item @click.native="clearRedis">
        <d2-icon name="power-off" class="d2-mr-5" />
        清理redis缓存
      </el-dropdown-item>
      <el-dropdown-item @click.native="open">
        <d2-icon name="power-off" class="d2-mr-5" />
        数字化大屏
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { get } from "../../../../model/service";

export default {
  computed: {
    ...mapState("d2admin/user", ["info"]),
  },
  methods: {
    ...mapActions("d2admin/account", ["logout"]),
    /**
     * @description 登出
     */
    logOff() {
      this.logout({
        confirm: true,
      });
    },

    // 清理redis缓存
    clearRedis() {
      this.$confirm("是否清除", "提示", {}).then((res) => {
        get("/service/demo/clearRedis")
          .then((res) => {
            // console.log(res)
            if (res.data.code == 0) {
              this.$message({
                message: "清除成功",
                type: "success",
                duration: 1500,
              });
            }
          })
          .catch((res) => {
            console.error(response);
            this.$message.error("清除失败");
          });
      });
    },

    // 打开数字化大屏界面
    open(){
      //  window.open('./digitalLargeScreen/index.html')
      window.open('./digitalLargeScreen2/index.html')
    }
  },
};
</script>
