import { mapState } from 'vuex'
import menuMixin from '../mixin/menu'
import { createMenu } from '../libs/util.menu'

import { getDynamicMenu } from '@/model/modules/user'

function filterAsyncRoutes(itemData, indexData) {
  const Subresult = []
  if (itemData.children != undefined) {
    itemData.children.forEach((item, index) => {
      const res = (Subresult[index] = {})
      res.path = '/' + itemData.menuUrl + '/' + item.menuUrl
      res.name = item.menuName
      res.component = resolve =>
        require([`@/views/${itemData.menuUrl}/${item.menuUrl}/index.vue`], resolve)
      res.meta = {
        title: item.menuName,
        cache: true
        // icon: item.menuClass
      }
      if (item.childrenMenu != undefined) {
        // 无限级递归菜单
        filterAsyncRoutes(item, index)
      } else {
        return false
      }
    })
  };
  return Subresult
}

export default {
  name: 'd2-layout-header-aside-menu-side',
  mixins: [
    menuMixin
  ],
  data() {
    return {
      asideDy: [],
    };
  },
  created() {
    const Firstresult = []
    getDynamicMenu({
      domain: 0,
      level: 20
    }).then(res => {
      //console.log("menus------->", res.data);
      const { content } = res.data.body
      content && content[0] && content[0].children && content[0].children.map((item, index) => {
        const routerAdd = (Firstresult[index] = {})
        routerAdd.path = '/' + item.menuUrl
        routerAdd.name = item.menuName
        routerAdd.component = resolve => require(['@/layout/header-aside'], resolve)
        routerAdd.meta = {
          title: item.menuName,
          icon: item.menuClass,
          cache: true
        }
        routerAdd.children = filterAsyncRoutes(item, index)
      })
      // console.log("const Firstresult = []->",Firstresult);
      this.asideDy = Firstresult;
      this.$forceUpdate();
    })


  },
  render(h) {
    return <div class="d2-layout-header-aside-menu-side">
      <d2-scrollbar>
        <el-menu
          collapse={this.asideCollapse}
          collapseTransition={this.asideTransition}
          uniqueOpened={true}
          defaultActive={this.$route.fullPath}
          ref="menu"
          onSelect={this.handleMenuSelect}>
          {this.asideDy ? this.asideDy.map(menu => createMenu.call(this, h, menu)) : null}
        </el-menu>
      </d2-scrollbar>
    </div>
  },
  computed: {
    ...mapState('d2admin/menu', [
      'aside',
      'asideCollapse',
      'asideTransition'
    ]),
  }
}
