import layoutHeaderAside from '@/layout/header-aside'

// 新增页面
export const knowledgeBaseAdd = {
  path: '/modCms',
  component: layoutHeaderAside,
  children: [
    {
      path: 'knowledgeBase/add',
      name: 'knowledgeBase_add',
      component: () => import('@/views/modCms/knowledgeBase/components/add')
    }
  ]
}
