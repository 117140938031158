// eslint-disable-next-line no-unused-vars
import util from '@/libs/util.js'

export default {
  methods: {
    async handleMenuSelect (index, indexPath) {
      localStorage.removeItem('menu_course')
      await this.$store.dispatch('d2admin/page/open', index)
      this.$router.push({
        path: index
      })
    }
  }
}
