import router from '@/router'
import VueRouter from 'vue-router'
import store from '@/store'
import util from '@/libs/util.js'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
const whiteList = ['/login'] // no redirect whitelist
let accessRoutes = []
let addRoutesBlock = false
/**
 * 路由拦截
 * 权限验证   
 */
router.$addRoutes = (params) => {
  const newRouter = new VueRouter({ mode: 'hash' }).matcher
  router.matcher = newRouter.matcher
  router.addRoutes(params)
}
router.beforeEach(async (to, from, next) => {
  // 确认已经加载多标签页数据 https://github.com/d2-projects/d2-admin/issues/201
  await store.dispatch('d2admin/page/isLoaded')
  // 确认已经加载组件尺寸设置 https://github.com/d2-projects/d2-admin/issues/198
  await store.dispatch('d2admin/size/isLoaded')
  await store.dispatch('d2admin/page/open', to)
  // 进度条
  NProgress.start()
  // 关闭搜索面板
  store.commit('d2admin/search/set', false)
  const hasToken = localStorage.getItem('token')
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/index/index' })
      NProgress.done()
    } else {
      next();
      // 没有添加过才添加router.options.routes[0].children.length < 10
      if (!addRoutesBlock) {
        addRoutesBlock = true;
        accessRoutes = await store.dispatch('d2admin/menu/generateRoutes')
        // //router.options.routes[0].children = router.options.routes[0].children.concat(accessRoutes)
        // frameInRoutes.concat(...accessRoutes)
        router.addRoutes(accessRoutes)
        store.commit('d2admin/menu/asideSet', accessRoutes)
        // store.commit('d2admin/menu/headerSet', accessRoutes )
        // 处理路由 得到每一级的路由设置
        store.commit('d2admin/page/init', [
          {
            path: '/index/index',
            name: 'index',
            meta: {
              title: '首页',
              icon: 'home'

            }
          },
          ...accessRoutes
        ])
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (to.path === '') {
        next('/login')
        return
      }
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
  // 进度条
  // NProgress.done()
})

router.afterEach(to => {
  // 多页控制 打开新的页面
  store.dispatch('d2admin/page/open', to)
  // 进度条
  NProgress.done()
  // 更改标题
  util.title(to.meta.title)
})
