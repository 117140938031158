import axios from 'axios'
import Qs from 'qs'
// eslint-disable-next-line no-unused-vars
import router from '@/router/index'
import {
  Message
} from 'element-ui'

const BASE_URL = process.env.VUE_APP_API_URL_BASE

const headersConfig = {
  'Content-Type': 'application/json'
}
// 创建axios实例
const service = axios.create({
  timeout: 30000 // 超时时间
})

service.defaults.withCredentials = false
service.defaults.headers.post['Content-Type'] =
  'application/json'
service.defaults.headers.get['Content-Type'] =
  'application/json'
// //添加request拦截器
service.interceptors.request.use(config => {
  return config
}, error => {
  Promise.reject(error)
})

// //响应拦截器
service.interceptors.response.use(
  response => {
    switch (response.status) {
      case 200:
        const res = {}
        res.status = response.status
        res.data = response.data
        if (res.data.code === 0 || res.data.code === 200) {
          return Promise.resolve(res)
        } else {
          Message.error(res.data.msg)
        }
        break
      default:
        Message.error(res.data.msg)
    }
  },
  error => {
    console.log(error)

    // if (localStorage.getItem('token')) {
    //   localStorage.removeItem('token');
    //   router.push({
    //     path: '/login'
    //   })
    // }
    if (!error.response) {
      // Message.error('操作失败！')
    } else if (error.response.status === 404) {
      // 跳转到404页面
      // router.push({
      //   path: '/404'
      // })
      Message.error('后台接口找不到了！')
    } else if (error.response.status === 401) {
      Message.error(error.response.data.msg)
      window.localStorage.clear()
      router.push({path: '/login'})
    } else {
      return Promise.reject(error.response.data)
    }
  }
)

// 封装post请求
export function post(url, data = {}, baseUrl = BASE_URL, headers = headersConfig) {
  if (localStorage.getItem('token')) {
    headers.Authorization = 'Bearer ' + localStorage.getItem('token');
    headers.clientOs = 'console'
  }
  return service({
    url: baseUrl + url,
    method: 'post',
    headers,
    data
  })
}

export function postnoAuth(url, data = {}, baseUrl = BASE_URL, headers = headersConfig) {
  if (localStorage.getItem('token')) {
    headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    headers.clientOs = 'console'
  }
  return service({
    url: baseUrl + url,
    method: 'post',
    headers,
    data: Qs.stringify(data)
  })
}
// 封装get请求
export function get(url, params = {}, baseUrl = BASE_URL, headers = headersConfig) {
  if (localStorage.getItem('token')) {
    headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    headers.clientOs = 'console'
  }
  return service({
    url: baseUrl + url,
    method: 'get',
    headers,
    params
  })
}

// 删除方法(resfulAPI常用)
export function deletes(url, params = {}, baseUrl = BASE_URL, headers = headersConfig) {
  if (localStorage.getItem('token')) {
    headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    headers.clientOs = 'console'
  }
  return service({
    url: baseUrl + url,
    method: 'delete',
    params: params,
    headers
  })
}

// 封装put方法 (resfulAPI常用)
export function put(url, data = {}, baseUrl = BASE_URL, headers = headersConfig) {
  if (localStorage.getItem('token')) {
    headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    headers.clientOs = 'console'
  }
  return service({
    url: baseUrl + url,
    method: 'put',
    headers,
    data: JSON.stringify(data)
  })
}
export default service
