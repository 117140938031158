// 设置文件
import setting from '@/setting.js'
import { getDynamicMenu } from '@/model/modules/user'
let Firstresult = []
export function filterAsyncRoutes (itemData, indexData) {
  const Subresult = []
  if (itemData.children != undefined) {
    itemData.children.forEach((item, index) => {
      const res = (Subresult[index] = {})
      res.path ='/'+itemData.menuUrl+'/'+item.menuUrl
      res.name = item.menuName
      res.component = resolve =>
        require([`@/views/${itemData.menuUrl}/${item.menuUrl}/index.vue`], resolve)
      res.meta = {
        title: item.menuName,
        cache: true
        // icon: item.menuClass
      }
      if (item.childrenMenu != undefined) {
        // 无限级递归菜单
        filterAsyncRoutes(item, index)
      } else {
        return false
      }
    })
  };
  return Subresult
}
export default {
  namespaced: true,
  state: {
    // 顶栏菜单
    header: [],
    // 侧栏菜单
    aside: [],
    // 侧边栏收缩
    asideCollapse: setting.menu.asideCollapse,
    // 侧边栏折叠动画
    asideTransition: setting.menu.asideTransition
  },
  getters: {
    /**
     * @description 返回当前的主题信息 不是一个名字 而是当前激活主题的所有数据
     * @param {Object} state state
     */
    asideSetting (state) {
      return state.aside.unshift([...{
        name: 'index',
        params: {},
        query: {},
        meta: {
          title: '首页'
        }
      }
      ])
    }
  },
  actions: {
    /**
     * 设置侧边栏展开或者收缩
     * @param {Object} context
     * @param {Boolean} collapse is collapse
     */
    async asideCollapseSet ({ state, dispatch }, collapse) {
      // store 赋值
      state.asideCollapse = collapse
      // 持久化
      await dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'menu.asideCollapse',
        value: state.asideCollapse,
        user: true
      }, { root: true })
    },
    /**
     * 切换侧边栏展开和收缩
     * @param {Object} context
     */
    async asideCollapseToggle ({ state, dispatch }) {
      // store 赋值
      state.asideCollapse = !state.asideCollapse
      // 持久化
      await dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'menu.asideCollapse',
        value: state.asideCollapse,
        user: true
      }, { root: true })
    },
    /**
     * 设置侧边栏折叠动画
     * @param {Object} context
     * @param {Boolean} transition is transition
     */
    async asideTransitionSet ({ state, dispatch }, transition) {
      // store 赋值
      state.asideTransition = transition
      // 持久化
      await dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'menu.asideTransition',
        value: state.asideTransition,
        user: true
      }, { root: true })
    },
    /**
     * 切换侧边栏折叠动画
     * @param {Object} context
     */
    async asideTransitionToggle ({ state, dispatch }) {
      // store 赋值
      state.asideTransition = !state.asideTransition
      // 持久化
      await dispatch('d2admin/db/set', {
        dbName: 'sys',
        path: 'menu.asideTransition',
        value: state.asideTransition,
        user: true
      }, { root: true })
    },
    /**
     * 持久化数据加载侧边栏设置
     * @param {Object} context
     */
    async asideLoad ({ state, dispatch }) {
      // store 赋值
      const menu = await dispatch('d2admin/db/get', {
        dbName: 'sys',
        path: 'menu',
        defaultValue: setting.menu,
        user: true
      }, { root: true })
      state.asideCollapse = menu.asideCollapse !== undefined ? menu.asideCollapse : setting.menu.asideCollapse
      state.asideTransition = menu.asideTransition !== undefined ? menu.asideTransition : setting.menu.asideTransition
    },
    /**
     * 加载动态左侧菜单
     */
    generateRoutes ({ commit }) {
      // 引入动态菜单接口
      return new Promise((resolve, reject) => {
        getDynamicMenu({
          domain: 0,
          level: 20
        }).then(res => {
          Firstresult = [];
          const { content } = res.data.body
          content&&content[0]&&content[0].children&&content[0].children.map((item, index) => {
            const routerAdd = (Firstresult[index] = {})
            routerAdd.path ='/'+item.menuUrl
            routerAdd.name = item.menuName
            routerAdd.component = resolve => require(['@/layout/header-aside'], resolve)
            routerAdd.meta = {
              title: item.menuName,
              icon: item.menuClass,
              cache: true
            }
            routerAdd.children = filterAsyncRoutes(item, index)
            commit('asideSet', Firstresult)
            resolve(Firstresult)
          })
        })
      })
    }
  },
  mutations: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    headerSet (state, menu) {
      // store 赋值
      state.header = menu
    },
    /**
     * @description 设置侧边栏菜单
     * @param {Object} state state
     * @param {Array} menu menu setting
     */
    asideSet (state, menu) {
      // store 赋值
      state.aside = menu
    }
  }
}
