/* eslint-disable no-undef */
/* eslint-disable no-const-assign */
/* eslint-disable no-unused-vars */
// Vue
import Vue from 'vue'
import i18n from './i18n'
import App from './App'
// 核心插件
import d2Admin from '@/plugin/d2admin'
// store
import store from '@/store/index'

// 菜单和路由设置
import router from './router'
import './permission'

import { menuHeader, menuAside } from '@/menu'

import moment from 'moment'

Vue.use(d2Admin)
Vue.prototype.$baseUrl = process.env.BASE_URL
Vue.prototype.$moment = moment
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created () {
    //console.log(router,frameInRoutes,"0000---99999")
    // // 处理路由 得到每一级的路由设置
    //this.$store.commit('d2admin/page/init',frameInRoutes)
    // // 设置顶栏菜单
    // this.$store.commit('d2admin/menu/headerSet', menuHeader)
    // // 初始化菜单搜索功能
    //this.$store.commit('d2admin/search/init', menuHeader)
  },
  mounted () {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow')
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load')
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get')
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen')
  },
  watch: {
    // 检测路由变化切换侧边栏内容
    '$route.matched': {
      handler (matched) {
        if (matched.length > 0) {
          const _side = menuAside.filter(menu => menu.path === matched[0].path)
          this.$store.commit('d2admin/menu/asideSet', _side.length > 0 ? _side[0].children : [])
        }
      },
      immediate: true
    }
  }
}).$mount('#app')
