import layoutHeaderAside from '@/layout/header-aside'

export const address = {
  path: '/appconsole',
  component: layoutHeaderAside,
  children: [
    {
      path: 'userinfo/address',
      name: 'address',
      component: () => import('@/views/appconsole/userinfo/address')
    }
  ]
}
