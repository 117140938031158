import layoutHeaderAside from '@/layout/header-aside'

import { courseVideo } from './modules/course/index'
import { purchaseOrderDetail } from './modules/modOrder/index'
import { liveroom } from './modules/liveroom'
import { codeBatch } from './modules/codeBatch'
import { knowledgeBaseAdd } from './modules/modCms'
import { readMaterialManage } from './modules/modList'
import { address } from './modules/appconsole'
/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/index',
    component: layoutHeaderAside,
    redirect: { name: 'index' },
    children: [
      // 首页
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '首页',
          auth: true
        },
        component: () => import('@/views/system/index')
      },
      // 系统 前端日志
      {
        path: 'log',
        name: 'log',
        meta: {
          title: '前端日志',
          auth: true
        },
        component: () => import('@/views/system/log')
      },
      // 刷新页面 必须保留
      {
        path: 'refresh',
        name: 'refresh',
        hidden: true,
        component: () => import('@/views/system/function/refresh')
      },
      // 页面重定向 必须保留
      {
        path: 'redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: () => import('@/views/system/function/redirect')
      }
    ]
  },
  courseVideo,
  liveroom,
  codeBatch,
  liveroom,
  purchaseOrderDetail,
  knowledgeBaseAdd,
  readMaterialManage,
  address

  // plugins,
  // components
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/system/login')
  },
  {
    path: '/',
    redirect: '/index/index'
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/system/error/404')
  }
]

/**
 * 错误页面
 */
// const errorPage = [

// ]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut
  // ...errorPage
]
