import layoutHeaderAside from '@/layout/header-aside'
export const liveroom = {
  path: '/modLive',
  component: layoutHeaderAside,
  children: [
    // 进入直播间
    {
      path: 'myLivesRoom',
      name: 'myLivesRoom',
      component: () => import('@/views/modLive/myLivesRoom')
    }
  ]
}
