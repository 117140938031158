/**
 * 用户信息相关的接口Api
 */
import { post, get, deletes, put } from '../../service'
import apiUrl from './api-url'

const { valicateCodeUrl, loginUrl, menuUrl, uploadHuaWei, uploadHuaWeiS } = apiUrl

const BASE_URL = process.env.VUE_APP_API_URL_BASE
const headersConfig = {
  'Content-Type': 'multipart/form-data',
  Authorization: 'Bearer ' + localStorage.getItem('token')
}
// 获取图片验证码接口
export const valicateCode = (params) => {
  return get(valicateCodeUrl, params)
}

// 登录接口
export const toLogin = (params) => {
  return post(loginUrl, params)
}

// 获取动态菜单接口
export const getDynamicMenu = (params = undefined) => {
  return post(menuUrl, params)
}

// 上传一张图片
export const uploadPic = (params = undefined) => {
  return post(uploadHuaWei, params, BASE_URL, headersConfig)
}

// 上传多章图片
export const uploadPics = (params = undefined) => {
  return post(uploadHuaWeiS, params, BASE_URL, headersConfig)
}
