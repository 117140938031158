import layoutHeaderAside from '@/layout/header-aside'
export const courseVideo = {
  path: '/modCourse',
  component: layoutHeaderAside,
  children: [
    {
      path: 'courseVideo',
      name: 'coursevideo',
      component: () => import('@/views/modCourse/menu_course_video')
    }
  ]
}
