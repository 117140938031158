import layoutHeaderAside from '@/layout/header-aside'
export const purchaseOrderDetail = {
  path: '/purchaseOrder',
  component: layoutHeaderAside,
  children: [
    {
      path: 'orderDetail/:id',
      name: 'orderDetail',
      component: () => import('@/views/modOrder/purchaseOrder/components/orderDetail')
    }
  ]
}

