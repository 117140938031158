import layoutHeaderAside from '@/layout/header-aside'
export const readMaterialManage = {
  path: '/modList',
  component: layoutHeaderAside,
  children: [
    {
      path: 'readMaterialManage',
      name: 'readMaterialManage',
      component: () => import('@/views/modList/readMaterialManage')
    }
  ]
}
