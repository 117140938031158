import layoutHeaderAside from '@/layout/header-aside'
export const codeBatch = {
  path: '/goodsManage',
  component: layoutHeaderAside,
  children: [
    {
      path: 'factoryBatch',
      name: 'factoryBatch',
      hidden: true,
      meta: {
        title: '出场批次',
        auth: true
      },
      component: () => import('@/views/goodsManage/factoryBatch/index')
    }
  ]
}
